@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Poppins", sans-serif;
}

.pagination-container {
  display: flex;
  list-style-type: none;
}
.pagination-container .pagination-item {
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
}
.pagination-container .pagination-item.dots:hover {
  background-color: transparent;
  cursor: default;
}
.pagination-container .pagination-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}
.pagination-container .pagination-item.selected {
  background-color: rgba(0, 0, 0, 0.08);
}
.pagination-container .pagination-item .arrow::before {
  position: relative;
  /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
  content: "";
  /* By using an em scale, the arrows will size with the font */
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}
.pagination-container .pagination-item .arrow.left {
  transform: rotate(-135deg) translate(-50%);
}
.pagination-container .pagination-item .arrow.right {
  transform: rotate(45deg);
}
.pagination-container .pagination-item.disabled {
  pointer-events: none;
}
.pagination-container .pagination-item.disabled .arrow::before {
  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
  border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}
.pagination-container .pagination-item.disabled:hover {
  background-color: transparent;
  cursor: default;
}

/* Popup */

.popUp-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.blurr {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  filter: blur(500px);
}

.popUp-container-hide {
  display: none;
}

.popUp-card {
  width: 500px;
  min-height: 450px;
  border: 2px solid #14397d;
  margin: 15px;
  background-color: white;
  border-radius: 15px;
  padding: 25px;
  position: relative;
  text-align: center;
}

.popUp-card .close-btn {
  position: absolute;
  right: 15px;
  top: 15px;
  padding: 5px 10px;
  color: #14397d;
  border: 2px solid #14397d;
  border-radius: 100%;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
}

.popUp-card span {
  font-weight: 600;
}

.popUp-card h2 {
  font-size: 30px;
  margin-top: 50px;
}

.popUp-card h4 {
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
}

.popUp-card p {
  font-size: 18px;
  margin: -35px 0 30px;
  text-align: left;
}

.space {
  margin-left: 30px;
}

/* INPUT FIELDS */
.inputbox {
  position: relative;
  width: 80%;
}

.inputbox input {
  position: relative;
  width: 100%;
  padding: 20px 10px 10px;
  background: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  color: white;
  font-size: 1em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  z-index: 10;
}

.inputbox span {
  position: absolute;
  left: 0;
  padding: 20px 10px 10px;
  font-size: 1.1em;
  color: #8f8f8f;
  letter-spacing: 00.05em;
  transition: 0.5s;
  pointer-events: none;
}

.inputbox input:valid ~ span,
.inputbox input:focus ~ span {
  color: #14397d;
  transform: translateX(-10px) translateY(-34px);
  font-size: 0, 75em;
}

.inputbox i {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #14397d;
  border-radius: 4px;
  transition: 0.5s;
  pointer-events: none;
  z-index: 9;
}

.inputbox input:valid ~ i,
.inputbox input:focus ~ i {
  height: 44px;
}
